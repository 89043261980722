import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';
const Fortress = window.cccisd && window.cccisd.fortress;

/*
 *  In Layouts, this component has been replaced with Laravel Header
 *  However, we still keep it in code because it is the header shown
 *  in Surveys if the "Show Header" option is selected
 */
const NavBar = ({ className }) => {
    const rolesWithManage = ['uberadmin', 'orgAdmin', 'guAdmin', 'siteAdmin', 'instructor'];
    const primaryNavLinks =
        Fortress.auth() && rolesWithManage.includes(Fortress.user.acting.role.handle)
            ? [{ url: '/manage', label: 'Manage' }]
            : [];

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logoPlaceholder}
            showAlertAction={false}
            primaryNavLinks={primaryNavLinks}
            showHomeAction={false}
            showAdminAction={Fortress.hasRole('uberadmin')}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
